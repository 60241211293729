import React from "react";

import { SortDirection } from "@tanstack/react-table";
import classNames from "classnames";

import { Sorter } from "./sorter";
import theme from "./theme.module.scss";

export interface HeadCellProps {
    children: React.ReactNode,
    isSorted?: SortDirection | false,
    onClick?: () => void,
    canSort?: boolean,
    width?: number,
}
export function HeadCell({
    children,
    isSorted,
    onClick,
    width,
    canSort = false,
}: HeadCellProps) {
    return (
        <th
            onClick={onClick}
            className={classNames(theme.cell, theme.headCell, canSort && theme.sortableHeadCell)}
            style={{ width }}
        >
            <div className={theme.headCellContent}>

                <div className={theme.headCellLabel}>
                    {children}
                </div>
                {canSort && isSorted !== undefined && <Sorter
                    sortDirection={isSorted}
                />}
            </div>
        </th>
    );
}
