import * as React from "react";

import theme from "./theme.module.scss";

interface TableProps {
    children: React.ReactNode,
}
export function Table({
    children,
}: TableProps) {

    return (
        <table className={theme.table}>
            {children}
        </table>
    );
}
