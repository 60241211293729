import React from "react";

import { RowData, Table as TSTableType } from "@tanstack/react-table";

import { Table } from "../table/table";

import { TSTRows } from "./tstBody";
import { TSTHead } from "./tstHead";

interface TSTTableProps<T extends RowData> {
    table: TSTableType<T>,
}
export function TSTTable<T extends RowData>({
    table,
}: TSTTableProps<T>) {
    return (
        <Table>
            <TSTHead table={table} />
            <TSTRows table={table} />
        </Table>
    );
}
