import { useAtom } from "jotai";

import { OrganizationFeatureFlag } from "types/feature_flags";
import { LocalFeatureFlag, localFeatureFlagsAtom } from "utils/flipper";
import { useFeatureFlagsQuery } from "utils/queries/useFeatureFlagsQuery";

export const useFeatureFlag = (flag: LocalFeatureFlag | OrganizationFeatureFlag): boolean => {
    const { data } = useFeatureFlagsQuery();
    const [localFlags] = useAtom(localFeatureFlagsAtom);

    if (flag in data) {
        return data[flag as OrganizationFeatureFlag]!;
    }

    const isEnabled = localFlags[flag as LocalFeatureFlag];
    return isEnabled;
};
