import React from "react";

import { flexRender, RowData, Table } from "@tanstack/react-table";

import { TableHead } from "../table/head";
import { HeadCell } from "../table/headCell";

export interface TSTHeadProps<T> {
    table: Table<T>,
}
export function TSTHead<T extends RowData>({
    table,
}: TSTHeadProps<T>) {
    return (
        <TableHead>
            {table.getHeaderGroups().map(headerGroup => (
                <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                        <HeadCell
                            key={header.id}
                            isSorted={header.column.getIsSorted()}
                            onClick={() => header.column.toggleSorting()}
                            canSort={header.column.getCanSort()}
                            width={header.column.getSize()}
                        >
                            {header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}
                        </HeadCell>
                    ))}
                </tr>
            ))}
        </TableHead>
    );
}
