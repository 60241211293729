import React from "react";

import { RowData, Table } from "@tanstack/react-table";

import { TSTRow } from "./tstRow";


interface TSTRowsProps<T extends RowData> {
    table: Table<T>,
}
export function TSTRows<T extends RowData>({
    table,
}: TSTRowsProps<T>) {
    const rowComponents = table.getRowModel().rows.map(row => {
        return (
            <TSTRow
                key={row.id}
                row={row}
            />
        );
    });
    return (
        <tbody>
            {rowComponents}
        </tbody>
    );
}
