import React from "react";

import classNames from "classnames";

import theme from "./theme.module.scss";

interface TableHeadProps {
    children: React.ReactNode,
}
export function TableHead({
    children,
}: TableHeadProps) {
    return (
        <thead className={classNames(theme.row, theme.head)}>
            {children}
        </thead>
    );
}
