import React from "react";

import { Steps as AntdSteps, StepsProps as AntdStepsProps } from "antd";
import classNames from "classnames";

import { TabSpec } from "componentTypes/tab";

import theme from "./theme.module.scss";


interface StepSpec extends TabSpec {
    status?: AntdStepsProps["status"],
}

export interface StepsProps {
    stepSpecs: StepSpec[],
    currentStepId: string,
    limitedWidth?: boolean,
}
export const Steps = ({
    currentStepId,
    stepSpecs,
    limitedWidth = false,
}: StepsProps) => {
    const currentStepIndex = stepSpecs.findIndex(stepSpec => stepSpec.id === currentStepId);
    const rootClasses = classNames(theme.steps, limitedWidth && theme.limitedWidth);
    const items = stepSpecs.map(stepSpec => ({
        key: stepSpec.id,
        title: stepSpec.label,
        status: stepSpec.status,
        onClick: (event: React.MouseEvent<HTMLElement>) => stepSpec.onClick({
            key: stepSpec.id,
            keyPath: [],
            item: stepSpec,
            domEvent: event,
        }),
    }));
    return (
        <AntdSteps
            className={rootClasses}
            current={currentStepIndex}
            size="small"
            items={items}
        />
    );
};
