import React from "react";

import { CaretDownFilled, CaretUpFilled } from "@ant-design/icons";
import classNames from "classnames";

import theme from "./theme.module.scss";

import "../../styles/main.less";

interface SorterProps {
    sortDirection: "asc" | "desc" | false,
}
export function Sorter({
    sortDirection,
}: SorterProps) {
    return (
        <div className={theme.sorter}>
            <CaretUpFilled className={classNames(sortDirection === "asc" && theme.caretActive, theme.caret)} />
            <CaretDownFilled className={classNames(sortDirection === "desc" && theme.caretActive, theme.caret)} />
        </div>
    );
}
