import { atom } from "jotai";
import { atomWithStorage } from "jotai/utils";

// A simple feature flipper based on localStorage.
// It enables us to merge/ship not yet fully implemented functionality behind disabled feature flags, so we
// do not break the existing system behaviour.
// To familiarize with the concept, please read https://martinfowler.com/articles/feature-toggles.html

const DEV_ONLY_FEATURES = [
    "orgEntryStrictMode",
    "controlViewStrictMode",
];
const IS_DEV_ENV = process.env.NODE_ENV === "development";

// List of all known features.
export type LocalFeatureFlag =
  "showFeatureFlipper" |
  "orgEntryStrictMode" |
  "controlViewStrictMode" |
  "controlFreezeAtom" |
  "createTestRiskDirectory" |
  "tanstackTables";

const FEATURES: Record<LocalFeatureFlag, FeatureDeclarationData> = {
    showFeatureFlipper: {
        description: "Enables toggling of the feature flipper. When disabled the button in the bottom-right corner is not shown.",
        defaultValue: () => SHOW_FEATURE_FLIPPER_DEFAULT_DOMAINS.includes(window.location.hostname) || IS_DEV_ENV,
    },
    orgEntryStrictMode: {
        description: "Enables React strict mode for the whole org application - not recommended",
        defaultValue: false,
    },
    controlViewStrictMode: {
        description: "Enables React strict mode for the control view - strongly recommended to keep enabled",
        defaultValue: true,
    },
    controlFreezeAtom: {
        description: "Freeze the atom state for the control page - use for debugging",
        defaultValue: false,
    },
    createTestRiskDirectory: {
        description: "Enables the creation of a test risk directory",
        defaultValue: false,
    },
    tanstackTables: {
        description: "Enables the use of tanstack tables",
        defaultValue: false,
    },
};

// On the following domains "showFeatureFlipper" is enabled by default.
const SHOW_FEATURE_FLIPPER_DEFAULT_DOMAINS = [
    "impero-dev.localhost",
    "testfoss.staging.next.imperocloud.dk",
    "testfoss.preproduction.rc.imperocloud.dk",
];

interface FeatureDeclarationData {
    description: string,
    defaultValue: boolean | (() => boolean),
}

export interface Feature {
    key: LocalFeatureFlag,
    description?: string,
    isEnabled: boolean,
}

function getDefaultValue(featureDecl: FeatureDeclarationData) {
    return typeof featureDecl.defaultValue === "function" ?
        featureDecl.defaultValue() :
        featureDecl.defaultValue;
}

export const defaultLocalFeatureFlags = Object.fromEntries(
    Object.entries(FEATURES).map(([key, value]) => {
        return [key, getDefaultValue(value)];
    })
) as Record<LocalFeatureFlag, boolean>;

const localFeatureFlagsAtoms = Object.fromEntries(
    Object.entries(FEATURES).map(([key, value]) => {
        return [key, atomWithStorage(`flipper:${key}`, getDefaultValue(value))];
    }));

export const localFeatureFlagsAtom = atom(
    get => Object.fromEntries(Object.entries(localFeatureFlagsAtoms).map(([key, value]) => [key, get(value)])) as Record<LocalFeatureFlag, boolean>,
    (get, set, newValue: Record<LocalFeatureFlag, boolean>) => {
        Object.entries(newValue).forEach(([key, isEnabled]) => {
            const targetFeatureAtom = localFeatureFlagsAtoms[key as LocalFeatureFlag];
            if (targetFeatureAtom) {
                set(targetFeatureAtom, isEnabled);
            } else {
                localFeatureFlagsAtoms[key] = atomWithStorage(`flipper:${key}`, isEnabled);
            }
        });
    }
);

export function getLocalFlagMetadata(flags: Record<LocalFeatureFlag, boolean>) {
    return Object.entries(FEATURES)
        .filter(([key]) => !(!IS_DEV_ENV && DEV_ONLY_FEATURES.includes(key)))
        .map(([keyStr, data]) => {
            const key = keyStr as LocalFeatureFlag;
            const { description } = data;
            const isEnabled = flags[key];
            return { key, description, isEnabled };
        });
}
