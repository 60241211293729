import React from "react";

import classNames from "classnames";

import theme from "./theme.module.scss";

export interface CellProps {
    children: React.ReactNode,
    sorted?: boolean,
}
export function Cell({
    children,
    sorted,
}: CellProps) {
    return (
        <td className={classNames(theme.cell, sorted && theme.cellSorted)}>
            {children}
        </td>
    );
}
